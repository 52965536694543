import React from 'react';

import PageLayout from '../components/page-layout';
import UserSettingsFormSecure from '../components/user-settings-form-secure';
import SEO from '../components/seo';
import { FormMessagesProvider } from '../components/form';
import { ContentWrapper, ContentContainer } from '../components/content-layout';
import UserSettingsMenu from '../components/user-settings-menu';

const MyAccountPage = () => {
  return (
    <PageLayout layout={`page`} path={`account`}>
      <SEO title="My Account - Secure Details" />
      <FormMessagesProvider>
        <ContentWrapper>
          <ContentContainer>
            <UserSettingsMenu path={'my-account-secure'} />
            <UserSettingsFormSecure />
          </ContentContainer>
        </ContentWrapper>
      </FormMessagesProvider>
    </PageLayout>
  );
};

export default MyAccountPage;
