import React from 'react';
import Link from '../link';
import styles from './user-settings-menu.module.scss';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const UserSettingsMenu = ({ path }) => (
  <ul className={styles.settingsMenu}>
    <li
      className={classnames(styles.settingsItem, {
        [styles.activeLink]: path === 'my-account'
      })}
    >
      <Link className={styles.settingsLink} to="/my-account">
        Basic settings
      </Link>
    </li>
    <li
      className={classnames(styles.settingsItem, {
        [styles.activeLink]: path === 'my-account-secure'
      })}
    >
      <Link className={styles.settingsLink} to="/my-account-secure">
        Email and Password
      </Link>
    </li>
  </ul>
);

UserSettingsMenu.propTypes = {
  path: PropTypes.string
};

export default UserSettingsMenu;
