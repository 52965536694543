import React, { useState, useContext } from 'react';

export const ActionButton = ({ label, action }) => (
  <div>
    <button onClick={action}>{label}</button>
  </div>
);

export const TextField = ({ label, id, onChange, value }) => (
  <div>
    <label htmlFor={id}>{label}</label>
    <input type="text" id={id} value={value} onChange={onChange} />
  </div>
);

export const PasswordField = ({ label, id, value, onChange }) => (
  <div>
    <label htmlFor={id}>{label}</label>
    <input type="password" id={id} value={value} onChange={onChange} />
  </div>
);

export const FormMessagesContext = React.createContext();

export const FormMessagesProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);

  const addMessage = ({ text, type }) => {
    setMessages((prevMessages) => {
      return prevMessages.concat({ text, type });
    });
  };

  const clearMessages = () => {
    setMessages([]);
  };

  const setErrorMessage = (text) => {
    clearMessages();
    addMessage({ text, type: 'error' });
  };

  const setStatusMessage = (text) => {
    clearMessages();
    addMessage({ text, type: 'status' });
  };

  return (
    <FormMessagesContext.Provider
      value={{
        messages,
        clearMessages,
        setErrorMessage,
        setStatusMessage
      }}
    >
      {children}
    </FormMessagesContext.Provider>
  );
};

export const FormMessages = () => {
  const { messages } = useContext(FormMessagesContext);

  return (
    <>
      {messages.map((message, index) => (
        <p key={index} className={`form-message form-message--${message.type}`}>
          {message.text}
        </p>
      ))}
    </>
  );
};
