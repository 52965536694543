import React, { useContext, useState, useEffect } from 'react';
import Button from '../button';
import { AuthContext } from '../auth-provider';
import { useFormState } from 'react-use-form-state';
import TextInput from '../text-input';

import styles from '../user-settings-form/user-settings-form.module.scss';
import { FormMessages, FormMessagesContext } from '../form';
import { validateEmail } from '../../utils';

const UserSettingsFormSecure = () => {
  const {
    currentUser,
    isInit,
    updateUserEmail,
    updateUserPassword,
    reauthenticateWithCredential,
    refreshCurrentUser
  } = useContext(AuthContext);

  const [needsReauthOp, setNeedsReauthOp] = useState(null);
  const { setErrorMessage, setStatusMessage } = useContext(FormMessagesContext);
  const [formState, { email, password }] = useFormState();

  // eslint-disable-next-line no-useless-escape
  const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const emailValidate = (value) => {
    if (!fieldNotEmpty) {
      return 'Email is required';
    }
    if (!EMAIL_REGEX.test(String(value).toLowerCase())) {
      return 'Not a valid email';
    }
  };

  const fieldNotEmpty = (field = '') => {
    return field.trim().length > 0;
  };

  const clearForm = () => {
    formState.clearField('newPassword');
    formState.clearField('newPasswordConfirm');
    formState.clearField('reauthPassword');
  };

  useEffect(() => {
    if (currentUser) {
      // Add user email to managed form state.
      formState.setField('email', currentUser.email);
    }
  }, [currentUser, formState]);

  if (!isInit) {
    return null;
  }

  if (!currentUser) {
    return <div>You're not allowed to access this page.</div>;
  }

  /**
   * Click handler: update email button.
   * @param {*} e
   */
  const handleUpdateEmail = async (e) => {
    e.preventDefault();
    if (!validateEmail(formState.values.email)) {
      setErrorMessage('Please provide a valid email address.');
      return;
    }

    try {
      await updateUserEmail(formState.values.email);
      setStatusMessage('Your email has been updated.');
      clearForm();
      refreshCurrentUser();
    } catch (error) {
      if (error.code === 'auth/requires-recent-login') {
        setNeedsReauthOp('email');
      } else {
        setErrorMessage(error.message);
      }
    }
  };

  /**
   * Click handler: update password button.
   *
   * @param {*} e
   */
  const handleUpdatePassword = async (e) => {
    e.preventDefault();

    if (formState.values.newPassword !== formState.values.newPasswordConfirm) {
      setErrorMessage('Passwords do not match.');
      return;
    }

    try {
      await updateUserPassword(formState.values.newPassword);
      setStatusMessage('Your password has been updated.');
      clearForm();
    } catch (error) {
      if (error.code === 'auth/requires-recent-login') {
        setNeedsReauthOp('password');
      } else {
        setErrorMessage(error.message);
      }
    }
  };

  /**
   * Click handler: handle re-authenticate and execute operation
   *
   * @param {*} e
   */
  const handleReauthAndOp = async (e) => {
    e.preventDefault();

    try {
      await reauthenticateWithCredential(
        currentUser.email,
        formState.values.reauthPassword
      );

      if (needsReauthOp === 'password') {
        await updateUserPassword(formState.values.newPassword);
        setStatusMessage('Your password has been updated.');
      } else if (needsReauthOp === 'email') {
        await updateUserEmail(formState.values.email);
        setStatusMessage('Your email has been updated.');
        refreshCurrentUser();
      }
      setNeedsReauthOp(null);
    } catch (error) {
      setErrorMessage(error.message);
      setNeedsReauthOp(null);
    }
    clearForm();
  };

  return (
    <div className={styles.userSettingsContainer}>
      <h3 className={styles.userSettingsHeading}>Update Email and Password</h3>
      {isInit && (
        <form className={styles.userSettingsForm}>
          {needsReauthOp && (
            <div>
              <p className={styles.emailConfirmMessage}>
                This operation requires that you confirm your identity once
                more.
                <br />
                Please provide your <em>current</em> password.
              </p>
              <TextInput
                className={styles.userFormInput}
                id="reauthPassword"
                labelSize="large"
                label="Enter Current Password"
                inputSize="largeText"
                maxLength={80}
                formStateProps={{
                  ...password({
                    name: 'reauthPassword',
                    validateOnChange: true,
                    validate: fieldNotEmpty
                  })
                }}
                valid={formState.validity['reauthPassword']}
                message={formState.errors['reauthPassword']}
              />
              <Button
                className={styles.submitButton}
                color="blue"
                onClick={(e) => handleReauthAndOp(e)}
                disabled={formState.validity['reauthPassword'] === false}
              >
                Confirm changing your {needsReauthOp}
              </Button>
            </div>
          )}
          {!needsReauthOp && (
            <>
              <div className={styles.formMessages}>
                <FormMessages />
              </div>
              <div>
                {/* Email */}
                <TextInput
                  className={styles.userFormInput}
                  id="email"
                  labelSize="large"
                  inputSize="largeText"
                  label="Email"
                  maxLength={80}
                  formStateProps={{
                    ...email({
                      name: 'email',
                      validateOnChange: true,
                      validate: emailValidate
                    })
                  }}
                  valid={formState.validity['email']}
                  message={formState.errors['email']}
                />
                <Button
                  className={styles.submitButton}
                  color="blue"
                  onClick={(e) => handleUpdateEmail(e)}
                  disabled={formState.validity['email'] === false}
                >
                  Update email
                </Button>

                {/* Change password */}
                <div>
                  <TextInput
                    className={styles.userFormInput}
                    id="newPassword"
                    labelSize="large"
                    label="New Password"
                    inputSize="largeText"
                    maxLength={80}
                    formStateProps={{
                      ...password({
                        name: 'newPassword',
                        validateOnChange: true,
                        validate: fieldNotEmpty
                      })
                    }}
                    valid={formState.validity['newPassword']}
                    message={formState.errors['newPassword']}
                  />
                  <TextInput
                    className={styles.userFormInput}
                    id="newPasswordConfirm"
                    labelSize="large"
                    label="New Password (confirm)"
                    inputSize="largeText"
                    maxLength={80}
                    formStateProps={{
                      ...password({
                        name: 'newPasswordConfirm',
                        validateOnChange: true,
                        validate: fieldNotEmpty
                      })
                    }}
                    valid={formState.validity['newPasswordConfirm']}
                    message={formState.errors['newPasswordConfirm']}
                  />
                  <Button
                    className={styles.submitButton}
                    color="blue"
                    onClick={(e) => handleUpdatePassword(e)}
                    disabled={
                      formState.values.newPassword === '' ||
                      formState.values.newPasswordConfirm === '' ||
                      formState.validity['newPassword'] === false ||
                      formState.validity['newPasswordConfirm'] === false
                    }
                  >
                    Update password
                  </Button>
                </div>
              </div>
            </>
          )}
        </form>
      )}
    </div>
  );
};

export default UserSettingsFormSecure;
